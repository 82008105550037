import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstractCRUD.service';
import { Pais } from '../modelo/pais.model';

@Injectable({
  providedIn: 'root'
})

export class PaisService extends AbstractService<Pais> {
  constructor(http: HttpClient) {
    super(http, '/pais');
  }
}
