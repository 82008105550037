import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstractCRUD.service';
import { Provincia } from '../modelo/provincia.model';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ProvinciaService extends AbstractService<Provincia> {
  constructor(private http2: HttpClient) {
    super(http2, '/provincia');
  }


}


