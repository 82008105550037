import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AbstractService } from './abstractCRUD.service';
import { Estante } from '../modelo/estante.model';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { PaginadorModel, ResponsePaginatorModel } from '../modelo/paginador.model';
import { Filtro } from '../modelo/filtros.model';

@Injectable({
  providedIn: 'root'
})


export class EstanteService extends AbstractService<Estante> {
  constructor(private http2: HttpClient) {
    super(http2, '/estante');
  }

  getAllEstanteriaByModulo(idModulo: number): Observable<ResponsePaginatorModel>{
    let parametros = new HttpParams()
    parametros = parametros.append('idModulo', idModulo.toString())
    parametros = parametros.append('page', (0).toString())
    parametros = parametros.append('size',(9999).toString())

    return this.http2.get<any>(`${this.urlBase}/see_all`, { params: parametros }).
    pipe(
      take(1)
    );
  }

  getAllByArea(idArea: number): Observable<any> {
    let parametros = new HttpParams()
    parametros = parametros.append('idArea', idArea.toString())
    parametros = parametros.append('page', (0).toString())
    parametros = parametros.append('size', (99999).toString())

    return this.http2.get<any>(`${this.urlBase}/see_all`, { params: parametros })
      .pipe(
        take(1),
      );
  };

  addMasiva( objeto: any): Observable<any> {
    return this.http2.post<any>(`${this.urlBase}/estante/generate/masiva`, objeto)
      .pipe(
        take(1),
      );
  };

  getExcel(filtros?: Filtro, id?: number): Observable<ResponsePaginatorModel> {
    let parametros = new HttpParams()
    
    for (const filtro in filtros) {
      filtros[filtro] != null ? parametros = parametros.append(`${filtro}`, filtros[filtro]) : null;
    }

   /*  return this.http2.get<any>(`${this.urlBase}/see_all/excel`, { params: parametros, responseType: 'blob' }).
      pipe(
        take(1)
      ); */
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
        // Puedes añadir otros encabezados según tus necesidades
      });
      return this.http2.get(`${this.urlBase}/see_all/excel`, {
        headers: headers,
        params: parametros,
        responseType: 'blob' // Indica que esperas un blob en la respuesta
      }).pipe(
        //catchError(this.handleError)
      );
  }

}
