import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from './abstractCRUD.service';
import { Modulo } from '../modelo/modulo.model';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ResponsePaginatorModel } from '../modelo/paginador.model';

@Injectable({
  providedIn: 'root'
})


export class ModuloService extends AbstractService<Modulo> {
  constructor(private http2: HttpClient) {
    super(http2, '/modulo');
  }

  getAllModuloByEstanteria(idEstanteria: number): Observable<ResponsePaginatorModel>{
    let parametros = new HttpParams()
    parametros = parametros.append('idEstanteria', idEstanteria.toString())
    parametros = parametros.append('page', (0).toString())
    parametros = parametros.append('size',(9999).toString())

    return this.http2.get<any>(`${this.urlBase}/see_all`, { params: parametros }).
    pipe(
      take(1)
    );
  }




}
