import { Injectable } from '@angular/core';
import { HttpClient,HttpParams,HttpHeaders } from '@angular/common/http';
import { AbstractService } from './abstractCRUD.service';
import { Pedido, PedidoPrepareRequestDTO } from '../modelo/pedido.model';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ItemContenedor } from '../modelo/item-contenedor.model';
import { Filtro } from '../modelo/filtros.model';
import { ResponsePaginatorModel } from '../modelo/paginador.model';

@Injectable({
  providedIn: 'root'
})

export class PedidoService extends AbstractService<Pedido> {
  constructor(private http2: HttpClient) {
    super(http2, '/pedido');
  };

  prepare(id: number, objeto: PedidoPrepareRequestDTO): Observable<Pedido> {
    return this.http2.patch<Pedido>(`${this.urlBase}/prepare/${id}`, objeto)
      .pipe(
        take(1),
      );
  };

  finalize(id: number, objeto: Pedido): Observable<Pedido> {
    return this.http2.patch<Pedido>(`${this.urlBase}/finalize/${id}`, objeto)
      .pipe(
        take(1),
      );
  };

  cancel(id: number): Observable<Pedido> {
    return this.http2.patch<Pedido>(`${this.urlBase}/cancel/${id}`, null)
      .pipe(
        take(1),
      );
  };


  generateItem(objeto: Pedido): Observable<Pedido> {
    return this.http2.post<Pedido>(`${this.urlBase}/items/generate`, objeto)
      .pipe(
        take(1),
      );
  };


  validateItem(idArea: number, idItem: number): Observable<ItemContenedor> {
    return this.http2.post<ItemContenedor>(`${this.urlBase}/item/validate`, {idArea, idItem})
      .pipe(
        take(1),
      );
  };

  validateContenedor(idArea: number, idContenedor: number, idTipoServicio: number): Observable<ItemContenedor> {
    return this.http2.post<ItemContenedor>(`${this.urlBase}/contenedor/validate`, {idArea, idContenedor, idTipoServicio})
      .pipe(
        take(1),
      );
  };

  getExcel(filtros?: Filtro): Observable<ResponsePaginatorModel> {
    let parametros = new HttpParams()
    
    for (const filtro in filtros) {
      filtros[filtro] != null ? parametros = parametros.append(`${filtro}`, filtros[filtro]) : null;
    }

   /*  return this.http2.get<any>(`${this.urlBase}/see_all/excel`, { params: parametros, responseType: 'blob' }).
      pipe(
        take(1)
      ); */
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
        // Puedes añadir otros encabezados según tus necesidades
      });
      return this.http2.get(`${this.urlBase}/export`, {
        headers: headers,
        params: parametros,
        responseType: 'blob' // Indica que esperas un blob en la respuesta
      }).pipe(
        //catchError(this.handleError)
      );
  }


}
