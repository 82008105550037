import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstractCRUD.service';
import { TipoServicio } from '../modelo/tipo-servicio.model';

@Injectable({
  providedIn: 'root'
})

export class TipoServicioService extends AbstractService<TipoServicio> {
  constructor(http: HttpClient) {
    super(http, '/tipo_servicio');
  }}
