import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstractCRUD.service';
import { CondicionIva } from '../modelo/condicion-iva.model';

@Injectable({
  providedIn: 'root'
})

export class CondicionIvaService extends AbstractService<CondicionIva> {
  constructor(http: HttpClient) {
    super(http, '/condicion_iva');
  }}
