import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from './abstractCRUD.service';
import { Estanteria } from '../modelo/estanteria.model';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ResponsePaginatorModel } from '../modelo/paginador.model';

@Injectable({
  providedIn: 'root'
})


export class EstanteriaService extends AbstractService<Estanteria> {
  constructor(private http2: HttpClient) {
    super(http2, '/estanteria');
  }


  getAllEstanteriaByIdPlanta(idPlanta: number): Observable<ResponsePaginatorModel>{
    let parametros = new HttpParams()
    parametros = parametros.append('idPlanta', idPlanta.toString())
    parametros = parametros.append('page', (0).toString())
    parametros = parametros.append('size',(9999).toString())

    return this.http2.get<any>(`${this.urlBase}/see_all`, { params: parametros }).
    pipe(
      take(1)
    );
  }


}
