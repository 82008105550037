import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstractCRUD.service';
import { TipoPedido } from '../modelo/tipo-pedido.model';

@Injectable({
  providedIn: 'root'
})

export class TipoPedidoService extends AbstractService<TipoPedido> {
  constructor(http: HttpClient) {
    super(http, '/tipo_pedido');
  }}
