import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractService } from './abstractCRUD.service';
import { Servicio } from '../modelo/servicio.model';
import { Observable } from 'rxjs';
import { Filtro } from '../modelo/filtros.model';
import { PaginadorModel, ResponsePaginatorModel } from '../modelo/paginador.model';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ServicioService extends AbstractService<Servicio> {
  constructor(private http2: HttpClient) {
    super(http2, '/servicio');
  }

}
