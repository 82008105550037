import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstractCRUD.service';
import { Departamento } from '../modelo/departamento.model';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class DepartamentoService extends AbstractService<Departamento> {
  constructor(private http2: HttpClient) {
    super(http2, '/departamento');
  }

}
