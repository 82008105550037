import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstractCRUD.service';
import { Categoria } from '../modelo/categoria.model';

@Injectable({
  providedIn: 'root'
})

export class CategoriaService extends AbstractService<Categoria> {
  constructor(http: HttpClient) {
    super(http, '/categoria');
  }}
